
import * as Sentry from '@sentry/vue'
import { Component, Vue } from 'vue-property-decorator'
import TeamMemberForm from '@/components/team/member_form.vue'
import TeamMemberCreateNewCharacterForm from '@/components/team/membership_new_character_form.vue'
import { Character } from '@/interfaces/character'
import { TeamCreateErrors, TeamCreateResponse } from '@/interfaces/responses'
import SavageAimMixin from '@/mixins/savage_aim_mixin'
import Tier from '@/interfaces/tier'

@Component({
  components: {
    TeamMemberForm,
    TeamMemberCreateNewCharacterForm,
  },
})
export default class TeamCreate extends SavageAimMixin {
  createFormRunning = false

  createFormUsed = false

  errors: TeamCreateErrors = {}

  requesting = false

  teamName = ''

  tierId = '-1'

  url = `/backend/api/team/`

  // Values for sending
  get bisListId(): string {
    try {
      return (this.$refs.form as TeamMemberForm).bisListId
    }
    catch (e) {
      return `${this.characterCreateForm.bisList?.id || '-1'}`
    }
  }

  get characters(): Character[] {
    return this.$store.state.characters
  }

  get characterCreateForm(): TeamMemberCreateNewCharacterForm {
    return this.$refs.characterCreateForm as TeamMemberCreateNewCharacterForm
  }

  get characterId(): string {
    try {
      return (this.$refs.form as TeamMemberForm).characterId
    }
    catch (e) {
      return `${this.characterCreateForm.character?.id || '-1'}`
    }
  }

  get tier(): Tier | null {
    return this.$store.state.tiers.find((tier: Tier) => tier.id === parseInt(this.tierId, 10)) || null
  }

  mounted(): void {
    document.title = 'Create New Team - Savage Aim'
  }

  async create(): Promise<void> {
    if (this.requesting) return
    this.requesting = true

    const body = JSON.stringify({
      name: this.teamName,
      tier_id: this.tierId,
      bis_list_id: this.bisListId,
      character_id: this.characterId,
    })
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
        body,
      })

      if (response.ok) {
        // Redirect back to the new bis list page
        const json = await response.json() as TeamCreateResponse
        this.$store.dispatch('fetchTeams')
        this.$router.push(`/team/${json.id}/`, () => {
          Vue.notify({ text: 'Team created successfully!', type: 'is-success' })
        })
      }
      else {
        super.handleError(response.status)
        this.errors = (await response.json() as TeamCreateErrors)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to create a Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
    finally {
      this.requesting = false
    }
  }

  async createCharAndTeam(): Promise<void> {
    if (this.createFormRunning) return
    this.errors = {}
    if (this.tier === null) {
      this.errors.tier_id = ['Please select a Tier!']
      return
    }
    this.createFormUsed = true
    this.createFormRunning = true
    const created = await this.characterCreateForm.createCharAndBIS(this.tier)
    if (created) await this.create()
    this.createFormRunning = false
  }

  async load(): Promise<void> {
    this.$forceUpdate()
  }
}
